import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../src/store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../src/store/breadcrumbs.js'), 'breadcrumbs.js')
  resolveStoreModules(require('../src/store/interfaces.ts'), 'interfaces.ts')
  resolveStoreModules(require('../src/store/mixins.ts'), 'mixins.ts')
  resolveStoreModules(require('../src/store/store.ts'), 'store.ts')
  resolveStoreModules(require('../src/store/modules/page-mixin.ts'), 'modules/page-mixin.ts')
  resolveStoreModules(require('../src/store/modules/word-replaces/index.ts'), 'modules/word-replaces/index.ts')
  resolveStoreModules(require('../src/store/modules/word-plurals/index.ts'), 'modules/word-plurals/index.ts')
  resolveStoreModules(require('../src/store/modules/word-cases/index.ts'), 'modules/word-cases/index.ts')
  resolveStoreModules(require('../src/store/modules/word-adjectives/index.ts'), 'modules/word-adjectives/index.ts')
  resolveStoreModules(require('../src/store/modules/widgets/index.ts'), 'modules/widgets/index.ts')
  resolveStoreModules(require('../src/store/modules/users/index.ts'), 'modules/users/index.ts')
  resolveStoreModules(require('../src/store/modules/users-type/index.ts'), 'modules/users-type/index.ts')
  resolveStoreModules(require('../src/store/modules/templates/index.ts'), 'modules/templates/index.ts')
  resolveStoreModules(require('../src/store/modules/tasks/index.ts'), 'modules/tasks/index.ts')
  resolveStoreModules(require('../src/store/modules/subscribers/index.ts'), 'modules/subscribers/index.ts')
  resolveStoreModules(require('../src/store/modules/stickers/index.ts'), 'modules/stickers/index.ts')
  resolveStoreModules(require('../src/store/modules/sites/index.ts'), 'modules/sites/index.ts')
  resolveStoreModules(require('../src/store/modules/seo-texts/index.ts'), 'modules/seo-texts/index.ts')
  resolveStoreModules(require('../src/store/modules/seo-pages/index.ts'), 'modules/seo-pages/index.ts')
  resolveStoreModules(require('../src/store/modules/search-text-replace/index.ts'), 'modules/search-text-replace/index.ts')
  resolveStoreModules(require('../src/store/modules/search-logs/index.ts'), 'modules/search-logs/index.ts')
  resolveStoreModules(require('../src/store/modules/roles/index.ts'), 'modules/roles/index.ts')
  resolveStoreModules(require('../src/store/modules/robots/index.ts'), 'modules/robots/index.ts')
  resolveStoreModules(require('../src/store/modules/reviews/index.ts'), 'modules/reviews/index.ts')
  resolveStoreModules(require('../src/store/modules/reindexer/index.ts'), 'modules/reindexer/index.ts')
  resolveStoreModules(require('../src/store/modules/regions/index.ts'), 'modules/regions/index.ts')
  resolveStoreModules(require('../src/store/modules/raffles/index.ts'), 'modules/raffles/index.ts')
  resolveStoreModules(require('../src/store/modules/promo-codes/index.ts'), 'modules/promo-codes/index.ts')
  resolveStoreModules(require('../src/store/modules/products/index.ts'), 'modules/products/index.ts')
  resolveStoreModules(require('../src/store/modules/product-offers/index.ts'), 'modules/product-offers/index.ts')
  resolveStoreModules(require('../src/store/modules/product-groups/index.ts'), 'modules/product-groups/index.ts')
  resolveStoreModules(require('../src/store/modules/product-fixed/index.ts'), 'modules/product-fixed/index.ts')
  resolveStoreModules(require('../src/store/modules/price-roles/index.ts'), 'modules/price-roles/index.ts')
  resolveStoreModules(require('../src/store/modules/price-role-assignment/index.ts'), 'modules/price-role-assignment/index.ts')
  resolveStoreModules(require('../src/store/modules/points/index.ts'), 'modules/points/index.ts')
  resolveStoreModules(require('../src/store/modules/permissions/index.ts'), 'modules/permissions/index.ts')
  resolveStoreModules(require('../src/store/modules/payments/index.ts'), 'modules/payments/index.ts')
  resolveStoreModules(require('../src/store/modules/partners/index.ts'), 'modules/partners/index.ts')
  resolveStoreModules(require('../src/store/modules/pages/index.ts'), 'modules/pages/index.ts')
  resolveStoreModules(require('../src/store/modules/page-templates/index.ts'), 'modules/page-templates/index.ts')
  resolveStoreModules(require('../src/store/modules/orders/index.ts'), 'modules/orders/index.ts')
  resolveStoreModules(require('../src/store/modules/order-statuses/index.ts'), 'modules/order-statuses/index.ts')
  resolveStoreModules(require('../src/store/modules/order-status-groups/index.ts'), 'modules/order-status-groups/index.ts')
  resolveStoreModules(require('../src/store/modules/order-offers/index.ts'), 'modules/order-offers/index.ts')
  resolveStoreModules(require('../src/store/modules/order-comments/index.ts'), 'modules/order-comments/index.ts')
  resolveStoreModules(require('../src/store/modules/order-bills/index.ts'), 'modules/order-bills/index.ts')
  resolveStoreModules(require('../src/store/modules/options-values/index.ts'), 'modules/options-values/index.ts')
  resolveStoreModules(require('../src/store/modules/offer/index.ts'), 'modules/offer/index.ts')
  resolveStoreModules(require('../src/store/modules/newsletters/index.ts'), 'modules/newsletters/index.ts')
  resolveStoreModules(require('../src/store/modules/news/index.ts'), 'modules/news/index.ts')
  resolveStoreModules(require('../src/store/modules/managers/index.ts'), 'modules/managers/index.ts')
  resolveStoreModules(require('../src/store/modules/main-page/index.ts'), 'modules/main-page/index.ts')
  resolveStoreModules(require('../src/store/modules/list-waiting/index.ts'), 'modules/list-waiting/index.ts')
  resolveStoreModules(require('../src/store/modules/generate/index.ts'), 'modules/generate/index.ts')
  resolveStoreModules(require('../src/store/modules/forms/index.ts'), 'modules/forms/index.ts')
  resolveStoreModules(require('../src/store/modules/format/index.ts'), 'modules/format/index.ts')
  resolveStoreModules(require('../src/store/modules/form-params/index.ts'), 'modules/form-params/index.ts')
  resolveStoreModules(require('../src/store/modules/files/index.ts'), 'modules/files/index.ts')
  resolveStoreModules(require('../src/store/modules/feeds/index.ts'), 'modules/feeds/index.ts')
  resolveStoreModules(require('../src/store/modules/event-triggers/index.ts'), 'modules/event-triggers/index.ts')
  resolveStoreModules(require('../src/store/modules/error-logs/index.ts'), 'modules/error-logs/index.ts')
  resolveStoreModules(require('../src/store/modules/email-templates/index.ts'), 'modules/email-templates/index.ts')
  resolveStoreModules(require('../src/store/modules/email-template-groups/index.ts'), 'modules/email-template-groups/index.ts')
  resolveStoreModules(require('../src/store/modules/discount-groups/index.ts'), 'modules/discount-groups/index.ts')
  resolveStoreModules(require('../src/store/modules/delivery/index.ts'), 'modules/delivery/index.ts')
  resolveStoreModules(require('../src/store/modules/delivery-prices/index.ts'), 'modules/delivery-prices/index.ts')
  resolveStoreModules(require('../src/store/modules/delivery-groups/index.ts'), 'modules/delivery-groups/index.ts')
  resolveStoreModules(require('../src/store/modules/dadata/index.ts'), 'modules/dadata/index.ts')
  resolveStoreModules(require('../src/store/modules/custom-filters/index.ts'), 'modules/custom-filters/index.ts')
  resolveStoreModules(require('../src/store/modules/currency-source/index.ts'), 'modules/currency-source/index.ts')
  resolveStoreModules(require('../src/store/modules/currencies/index.ts'), 'modules/currencies/index.ts')
  resolveStoreModules(require('../src/store/modules/config/index.ts'), 'modules/config/index.ts')
  resolveStoreModules(require('../src/store/modules/collections/index.ts'), 'modules/collections/index.ts')
  resolveStoreModules(require('../src/store/modules/chat-channels/index.ts'), 'modules/chat-channels/index.ts')
  resolveStoreModules(require('../src/store/modules/categories/index.ts'), 'modules/categories/index.ts')
  resolveStoreModules(require('../src/store/modules/calculators/index.ts'), 'modules/calculators/index.ts')
  resolveStoreModules(require('../src/store/modules/calculator-types/index.ts'), 'modules/calculator-types/index.ts')
  resolveStoreModules(require('../src/store/modules/calculator-elements/index.ts'), 'modules/calculator-elements/index.ts')
  resolveStoreModules(require('../src/store/modules/calculator-conditions/index.ts'), 'modules/calculator-conditions/index.ts')
  resolveStoreModules(require('../src/store/modules/calculations/index.ts'), 'modules/calculations/index.ts')
  resolveStoreModules(require('../src/store/modules/bonus/index.ts'), 'modules/bonus/index.ts')
  resolveStoreModules(require('../src/store/modules/bonus-type/index.ts'), 'modules/bonus-type/index.ts')
  resolveStoreModules(require('../src/store/modules/bonus-log/index.ts'), 'modules/bonus-log/index.ts')
  resolveStoreModules(require('../src/store/modules/bonus-account/index.ts'), 'modules/bonus-account/index.ts')
  resolveStoreModules(require('../src/store/modules/baskets/index.ts'), 'modules/baskets/index.ts')
  resolveStoreModules(require('../src/store/modules/banner-blocks/index.ts'), 'modules/banner-blocks/index.ts')
  resolveStoreModules(require('../src/store/modules/auth/index.ts'), 'modules/auth/index.ts')
  resolveStoreModules(require('../src/store/modules/archive/index.ts'), 'modules/archive/index.ts')
  resolveStoreModules(require('../src/store/modules/archive/interfaces.ts'), 'modules/archive/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/archive/store.ts'), 'modules/archive/store.ts')
  resolveStoreModules(require('../src/store/modules/auth/interfaces.ts'), 'modules/auth/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/auth/store.ts'), 'modules/auth/store.ts')
  resolveStoreModules(require('../src/store/modules/banner-blocks/banner-blocks-mixin.ts'), 'modules/banner-blocks/banner-blocks-mixin.ts')
  resolveStoreModules(require('../src/store/modules/banner-blocks/interface.ts'), 'modules/banner-blocks/interface.ts')
  resolveStoreModules(require('../src/store/modules/banner-blocks/store.ts'), 'modules/banner-blocks/store.ts')
  resolveStoreModules(require('../src/store/modules/baskets/baskets-mixin.ts'), 'modules/baskets/baskets-mixin.ts')
  resolveStoreModules(require('../src/store/modules/baskets/interfaces.ts'), 'modules/baskets/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/baskets/store.ts'), 'modules/baskets/store.ts')
  resolveStoreModules(require('../src/store/modules/bonus-account/bonus-account-mixin.ts'), 'modules/bonus-account/bonus-account-mixin.ts')
  resolveStoreModules(require('../src/store/modules/bonus-account/interfaces.ts'), 'modules/bonus-account/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/bonus-account/store.ts'), 'modules/bonus-account/store.ts')
  resolveStoreModules(require('../src/store/modules/bonus-log/bonus-log-mixin.ts'), 'modules/bonus-log/bonus-log-mixin.ts')
  resolveStoreModules(require('../src/store/modules/bonus-log/interfaces.ts'), 'modules/bonus-log/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/bonus-log/store.ts'), 'modules/bonus-log/store.ts')
  resolveStoreModules(require('../src/store/modules/bonus-type/bonus-type-mixin.ts'), 'modules/bonus-type/bonus-type-mixin.ts')
  resolveStoreModules(require('../src/store/modules/bonus-type/interfaces.ts'), 'modules/bonus-type/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/bonus-type/store.ts'), 'modules/bonus-type/store.ts')
  resolveStoreModules(require('../src/store/modules/bonus/bonus-mixin.ts'), 'modules/bonus/bonus-mixin.ts')
  resolveStoreModules(require('../src/store/modules/bonus/interfaces.ts'), 'modules/bonus/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/bonus/store.ts'), 'modules/bonus/store.ts')
  resolveStoreModules(require('../src/store/modules/calculations/interfaces.ts'), 'modules/calculations/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/calculations/store.ts'), 'modules/calculations/store.ts')
  resolveStoreModules(require('../src/store/modules/calculator-conditions/interfaces.ts'), 'modules/calculator-conditions/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/calculator-conditions/mixin.ts'), 'modules/calculator-conditions/mixin.ts')
  resolveStoreModules(require('../src/store/modules/calculator-conditions/store.ts'), 'modules/calculator-conditions/store.ts')
  resolveStoreModules(require('../src/store/modules/calculator-elements/interfaces.ts'), 'modules/calculator-elements/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/calculator-elements/mixin.ts'), 'modules/calculator-elements/mixin.ts')
  resolveStoreModules(require('../src/store/modules/calculator-elements/store.ts'), 'modules/calculator-elements/store.ts')
  resolveStoreModules(require('../src/store/modules/calculator-types/interfaces.ts'), 'modules/calculator-types/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/calculator-types/mixin.ts'), 'modules/calculator-types/mixin.ts')
  resolveStoreModules(require('../src/store/modules/calculator-types/store.ts'), 'modules/calculator-types/store.ts')
  resolveStoreModules(require('../src/store/modules/calculators/interfaces.ts'), 'modules/calculators/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/calculators/mixin.ts'), 'modules/calculators/mixin.ts')
  resolveStoreModules(require('../src/store/modules/calculators/store.ts'), 'modules/calculators/store.ts')
  resolveStoreModules(require('../src/store/modules/categories/categories-mixin.ts'), 'modules/categories/categories-mixin.ts')
  resolveStoreModules(require('../src/store/modules/categories/interfaces.ts'), 'modules/categories/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/categories/store.ts'), 'modules/categories/store.ts')
  resolveStoreModules(require('../src/store/modules/chat-channels/interfaces.ts'), 'modules/chat-channels/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/chat-channels/mixin.ts'), 'modules/chat-channels/mixin.ts')
  resolveStoreModules(require('../src/store/modules/chat-channels/store.ts'), 'modules/chat-channels/store.ts')
  resolveStoreModules(require('../src/store/modules/collections/collections-mixin.ts'), 'modules/collections/collections-mixin.ts')
  resolveStoreModules(require('../src/store/modules/collections/interfaces.ts'), 'modules/collections/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/collections/store.ts'), 'modules/collections/store.ts')
  resolveStoreModules(require('../src/store/modules/config/config-mixin.ts'), 'modules/config/config-mixin.ts')
  resolveStoreModules(require('../src/store/modules/config/interfaces.ts'), 'modules/config/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/config/store.ts'), 'modules/config/store.ts')
  resolveStoreModules(require('../src/store/modules/currencies/currencies-mixin.ts'), 'modules/currencies/currencies-mixin.ts')
  resolveStoreModules(require('../src/store/modules/currencies/interfaces.ts'), 'modules/currencies/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/currencies/store.ts'), 'modules/currencies/store.ts')
  resolveStoreModules(require('../src/store/modules/currency-source/interface.ts'), 'modules/currency-source/interface.ts')
  resolveStoreModules(require('../src/store/modules/custom-filters/custom-filters-mixin.ts'), 'modules/custom-filters/custom-filters-mixin.ts')
  resolveStoreModules(require('../src/store/modules/custom-filters/interfaces.ts'), 'modules/custom-filters/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/custom-filters/store.ts'), 'modules/custom-filters/store.ts')
  resolveStoreModules(require('../src/store/modules/dadata/interface.ts'), 'modules/dadata/interface.ts')
  resolveStoreModules(require('../src/store/modules/delivery-groups/delivery-groups-mixin.ts'), 'modules/delivery-groups/delivery-groups-mixin.ts')
  resolveStoreModules(require('../src/store/modules/delivery-groups/interfaces.ts'), 'modules/delivery-groups/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/delivery-groups/store.ts'), 'modules/delivery-groups/store.ts')
  resolveStoreModules(require('../src/store/modules/delivery-prices/delivery-prices-mixin.ts'), 'modules/delivery-prices/delivery-prices-mixin.ts')
  resolveStoreModules(require('../src/store/modules/delivery-prices/interfaces.ts'), 'modules/delivery-prices/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/delivery-prices/store.ts'), 'modules/delivery-prices/store.ts')
  resolveStoreModules(require('../src/store/modules/delivery/delivery-mixin.ts'), 'modules/delivery/delivery-mixin.ts')
  resolveStoreModules(require('../src/store/modules/delivery/interfaces.ts'), 'modules/delivery/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/delivery/store.ts'), 'modules/delivery/store.ts')
  resolveStoreModules(require('../src/store/modules/discount-groups/discount-groups-mixin.ts'), 'modules/discount-groups/discount-groups-mixin.ts')
  resolveStoreModules(require('../src/store/modules/discount-groups/interfaces.ts'), 'modules/discount-groups/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/discount-groups/store.ts'), 'modules/discount-groups/store.ts')
  resolveStoreModules(require('../src/store/modules/email-template-groups/email-template-groups-mixin.ts'), 'modules/email-template-groups/email-template-groups-mixin.ts')
  resolveStoreModules(require('../src/store/modules/email-template-groups/interfaces.ts'), 'modules/email-template-groups/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/email-template-groups/store.ts'), 'modules/email-template-groups/store.ts')
  resolveStoreModules(require('../src/store/modules/email-templates/email-templates-mixin.ts'), 'modules/email-templates/email-templates-mixin.ts')
  resolveStoreModules(require('../src/store/modules/email-templates/interfaces.ts'), 'modules/email-templates/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/email-templates/store.ts'), 'modules/email-templates/store.ts')
  resolveStoreModules(require('../src/store/modules/error-logs/interfaces.ts'), 'modules/error-logs/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/error-logs/store.ts'), 'modules/error-logs/store.ts')
  resolveStoreModules(require('../src/store/modules/event-triggers/interfaces.ts'), 'modules/event-triggers/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/event-triggers/mixin.ts'), 'modules/event-triggers/mixin.ts')
  resolveStoreModules(require('../src/store/modules/event-triggers/store.ts'), 'modules/event-triggers/store.ts')
  resolveStoreModules(require('../src/store/modules/feeds/feeds-mixin.ts'), 'modules/feeds/feeds-mixin.ts')
  resolveStoreModules(require('../src/store/modules/feeds/interfaces.ts'), 'modules/feeds/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/feeds/store.ts'), 'modules/feeds/store.ts')
  resolveStoreModules(require('../src/store/modules/files/files-mixin.ts'), 'modules/files/files-mixin.ts')
  resolveStoreModules(require('../src/store/modules/files/interfaces.ts'), 'modules/files/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/files/store.ts'), 'modules/files/store.ts')
  resolveStoreModules(require('../src/store/modules/form-params/form-params-mixin.ts'), 'modules/form-params/form-params-mixin.ts')
  resolveStoreModules(require('../src/store/modules/form-params/interfaces.ts'), 'modules/form-params/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/form-params/store.ts'), 'modules/form-params/store.ts')
  resolveStoreModules(require('../src/store/modules/format/interfaces.ts'), 'modules/format/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/forms/forms-mixin.ts'), 'modules/forms/forms-mixin.ts')
  resolveStoreModules(require('../src/store/modules/forms/interfaces.ts'), 'modules/forms/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/forms/store.ts'), 'modules/forms/store.ts')
  resolveStoreModules(require('../src/store/modules/generate/generate-mixin.ts'), 'modules/generate/generate-mixin.ts')
  resolveStoreModules(require('../src/store/modules/generate/interfaces.ts'), 'modules/generate/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/generate/store.ts'), 'modules/generate/store.ts')
  resolveStoreModules(require('../src/store/modules/list-waiting/interfaces.ts'), 'modules/list-waiting/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/list-waiting/list-waiting-mixin.ts'), 'modules/list-waiting/list-waiting-mixin.ts')
  resolveStoreModules(require('../src/store/modules/list-waiting/store.ts'), 'modules/list-waiting/store.ts')
  resolveStoreModules(require('../src/store/modules/main-page/interfaces.ts'), 'modules/main-page/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/main-page/main-page-mixin.ts'), 'modules/main-page/main-page-mixin.ts')
  resolveStoreModules(require('../src/store/modules/main-page/store.ts'), 'modules/main-page/store.ts')
  resolveStoreModules(require('../src/store/modules/managers/interfaces.ts'), 'modules/managers/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/managers/managers-mixin.ts'), 'modules/managers/managers-mixin.ts')
  resolveStoreModules(require('../src/store/modules/managers/store.ts'), 'modules/managers/store.ts')
  resolveStoreModules(require('../src/store/modules/news/interfaces.ts'), 'modules/news/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/news/news-mixin.ts'), 'modules/news/news-mixin.ts')
  resolveStoreModules(require('../src/store/modules/news/store.ts'), 'modules/news/store.ts')
  resolveStoreModules(require('../src/store/modules/newsletters/constants.ts'), 'modules/newsletters/constants.ts')
  resolveStoreModules(require('../src/store/modules/newsletters/interfaces.ts'), 'modules/newsletters/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/newsletters/newsletters-mixin.ts'), 'modules/newsletters/newsletters-mixin.ts')
  resolveStoreModules(require('../src/store/modules/newsletters/store.ts'), 'modules/newsletters/store.ts')
  resolveStoreModules(require('../src/store/modules/offer/interfaces.ts'), 'modules/offer/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/offer/offer-mixin.ts'), 'modules/offer/offer-mixin.ts')
  resolveStoreModules(require('../src/store/modules/offer/store.ts'), 'modules/offer/store.ts')
  resolveStoreModules(require('../src/store/modules/options-values/interfaces.ts'), 'modules/options-values/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/options-values/options-values-mixin.ts'), 'modules/options-values/options-values-mixin.ts')
  resolveStoreModules(require('../src/store/modules/options-values/store.ts'), 'modules/options-values/store.ts')
  resolveStoreModules(require('../src/store/modules/order-bills/interfaces.ts'), 'modules/order-bills/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/order-bills/order-bills-mixin.ts'), 'modules/order-bills/order-bills-mixin.ts')
  resolveStoreModules(require('../src/store/modules/order-bills/store.ts'), 'modules/order-bills/store.ts')
  resolveStoreModules(require('../src/store/modules/order-comments/interfaces.ts'), 'modules/order-comments/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/order-comments/oder-comments-mixin.ts'), 'modules/order-comments/oder-comments-mixin.ts')
  resolveStoreModules(require('../src/store/modules/order-comments/store.ts'), 'modules/order-comments/store.ts')
  resolveStoreModules(require('../src/store/modules/order-offers/interfaces.ts'), 'modules/order-offers/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/order-offers/order-offers-mixin.ts'), 'modules/order-offers/order-offers-mixin.ts')
  resolveStoreModules(require('../src/store/modules/order-offers/store.ts'), 'modules/order-offers/store.ts')
  resolveStoreModules(require('../src/store/modules/order-status-groups/interfaces.ts'), 'modules/order-status-groups/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/order-status-groups/order-status-groups-mixin.ts'), 'modules/order-status-groups/order-status-groups-mixin.ts')
  resolveStoreModules(require('../src/store/modules/order-status-groups/store.ts'), 'modules/order-status-groups/store.ts')
  resolveStoreModules(require('../src/store/modules/order-statuses/interfaces.ts'), 'modules/order-statuses/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/order-statuses/order-statuses-mixin.ts'), 'modules/order-statuses/order-statuses-mixin.ts')
  resolveStoreModules(require('../src/store/modules/order-statuses/store.ts'), 'modules/order-statuses/store.ts')
  resolveStoreModules(require('../src/store/modules/orders/interfaces.ts'), 'modules/orders/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/orders/orders-mixin.ts'), 'modules/orders/orders-mixin.ts')
  resolveStoreModules(require('../src/store/modules/orders/store.ts'), 'modules/orders/store.ts')
  resolveStoreModules(require('../src/store/modules/page-templates/interfaces.ts'), 'modules/page-templates/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/page-templates/mixin.ts'), 'modules/page-templates/mixin.ts')
  resolveStoreModules(require('../src/store/modules/page-templates/store.ts'), 'modules/page-templates/store.ts')
  resolveStoreModules(require('../src/store/modules/pages/interfaces.ts'), 'modules/pages/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/pages/pages-mixin.ts'), 'modules/pages/pages-mixin.ts')
  resolveStoreModules(require('../src/store/modules/pages/store.ts'), 'modules/pages/store.ts')
  resolveStoreModules(require('../src/store/modules/partners/interfaces.ts'), 'modules/partners/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/partners/partners-mixin.ts'), 'modules/partners/partners-mixin.ts')
  resolveStoreModules(require('../src/store/modules/partners/store.ts'), 'modules/partners/store.ts')
  resolveStoreModules(require('../src/store/modules/payments/interfaces.ts'), 'modules/payments/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/payments/payments-mixin.ts'), 'modules/payments/payments-mixin.ts')
  resolveStoreModules(require('../src/store/modules/payments/store.ts'), 'modules/payments/store.ts')
  resolveStoreModules(require('../src/store/modules/permissions/interfaces.ts'), 'modules/permissions/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/permissions/permisions-mixin.ts'), 'modules/permissions/permisions-mixin.ts')
  resolveStoreModules(require('../src/store/modules/permissions/store.ts'), 'modules/permissions/store.ts')
  resolveStoreModules(require('../src/store/modules/points/interfaces.ts'), 'modules/points/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/points/points-mixin.ts'), 'modules/points/points-mixin.ts')
  resolveStoreModules(require('../src/store/modules/points/store.ts'), 'modules/points/store.ts')
  resolveStoreModules(require('../src/store/modules/price-role-assignment/interfaces.ts'), 'modules/price-role-assignment/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/price-role-assignment/price-role-assignment-mixin.ts'), 'modules/price-role-assignment/price-role-assignment-mixin.ts')
  resolveStoreModules(require('../src/store/modules/price-role-assignment/store.ts'), 'modules/price-role-assignment/store.ts')
  resolveStoreModules(require('../src/store/modules/price-roles/interfaces.ts'), 'modules/price-roles/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/price-roles/price-roles-mixin.ts'), 'modules/price-roles/price-roles-mixin.ts')
  resolveStoreModules(require('../src/store/modules/price-roles/store.ts'), 'modules/price-roles/store.ts')
  resolveStoreModules(require('../src/store/modules/product-fixed/interfaces.ts'), 'modules/product-fixed/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/product-fixed/product-fixed-mixin.ts'), 'modules/product-fixed/product-fixed-mixin.ts')
  resolveStoreModules(require('../src/store/modules/product-fixed/store.ts'), 'modules/product-fixed/store.ts')
  resolveStoreModules(require('../src/store/modules/product-groups/interfaces.ts'), 'modules/product-groups/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/product-groups/product-groups-mixin.ts'), 'modules/product-groups/product-groups-mixin.ts')
  resolveStoreModules(require('../src/store/modules/product-groups/store.ts'), 'modules/product-groups/store.ts')
  resolveStoreModules(require('../src/store/modules/product-offers/interfaces.ts'), 'modules/product-offers/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/product-offers/product-offers-mixin.ts'), 'modules/product-offers/product-offers-mixin.ts')
  resolveStoreModules(require('../src/store/modules/product-offers/store.ts'), 'modules/product-offers/store.ts')
  resolveStoreModules(require('../src/store/modules/products/constants.ts'), 'modules/products/constants.ts')
  resolveStoreModules(require('../src/store/modules/products/interfaces.ts'), 'modules/products/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/products/products-mixin.ts'), 'modules/products/products-mixin.ts')
  resolveStoreModules(require('../src/store/modules/products/store.ts'), 'modules/products/store.ts')
  resolveStoreModules(require('../src/store/modules/promo-codes/interfaces.ts'), 'modules/promo-codes/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/promo-codes/promo-codes-mixin.ts'), 'modules/promo-codes/promo-codes-mixin.ts')
  resolveStoreModules(require('../src/store/modules/promo-codes/store.ts'), 'modules/promo-codes/store.ts')
  resolveStoreModules(require('../src/store/modules/raffles/interface.ts'), 'modules/raffles/interface.ts')
  resolveStoreModules(require('../src/store/modules/raffles/raffles-mixin.ts'), 'modules/raffles/raffles-mixin.ts')
  resolveStoreModules(require('../src/store/modules/raffles/store.ts'), 'modules/raffles/store.ts')
  resolveStoreModules(require('../src/store/modules/regions/interfaces.ts'), 'modules/regions/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/regions/regions-mixin.ts'), 'modules/regions/regions-mixin.ts')
  resolveStoreModules(require('../src/store/modules/regions/store.ts'), 'modules/regions/store.ts')
  resolveStoreModules(require('../src/store/modules/reindexer/interfaces.ts'), 'modules/reindexer/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/reindexer/reindexer-mixin.ts'), 'modules/reindexer/reindexer-mixin.ts')
  resolveStoreModules(require('../src/store/modules/reindexer/store.ts'), 'modules/reindexer/store.ts')
  resolveStoreModules(require('../src/store/modules/reviews/interfaces.ts'), 'modules/reviews/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/reviews/reviews-mixin.ts'), 'modules/reviews/reviews-mixin.ts')
  resolveStoreModules(require('../src/store/modules/reviews/store.ts'), 'modules/reviews/store.ts')
  resolveStoreModules(require('../src/store/modules/robots/interfaces.ts'), 'modules/robots/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/robots/robots-mixin.ts'), 'modules/robots/robots-mixin.ts')
  resolveStoreModules(require('../src/store/modules/robots/store.ts'), 'modules/robots/store.ts')
  resolveStoreModules(require('../src/store/modules/roles/interfaces.ts'), 'modules/roles/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/roles/roles-mixin.ts'), 'modules/roles/roles-mixin.ts')
  resolveStoreModules(require('../src/store/modules/roles/store.ts'), 'modules/roles/store.ts')
  resolveStoreModules(require('../src/store/modules/search-logs/interfaces.ts'), 'modules/search-logs/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/search-logs/search-logs-mixin.ts'), 'modules/search-logs/search-logs-mixin.ts')
  resolveStoreModules(require('../src/store/modules/search-logs/store.ts'), 'modules/search-logs/store.ts')
  resolveStoreModules(require('../src/store/modules/search-text-replace/interfaces.ts'), 'modules/search-text-replace/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/search-text-replace/search-text-replace-mixin.ts'), 'modules/search-text-replace/search-text-replace-mixin.ts')
  resolveStoreModules(require('../src/store/modules/search-text-replace/store.ts'), 'modules/search-text-replace/store.ts')
  resolveStoreModules(require('../src/store/modules/seo-pages/interfaces.ts'), 'modules/seo-pages/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/seo-pages/seo-pages-mixin.ts'), 'modules/seo-pages/seo-pages-mixin.ts')
  resolveStoreModules(require('../src/store/modules/seo-pages/store.ts'), 'modules/seo-pages/store.ts')
  resolveStoreModules(require('../src/store/modules/seo-texts/interfaces.ts'), 'modules/seo-texts/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/seo-texts/seo-texts-mixin.ts'), 'modules/seo-texts/seo-texts-mixin.ts')
  resolveStoreModules(require('../src/store/modules/seo-texts/store.ts'), 'modules/seo-texts/store.ts')
  resolveStoreModules(require('../src/store/modules/sites/interfaces.ts'), 'modules/sites/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/sites/sites-mixin.ts'), 'modules/sites/sites-mixin.ts')
  resolveStoreModules(require('../src/store/modules/sites/store.ts'), 'modules/sites/store.ts')
  resolveStoreModules(require('../src/store/modules/stickers/interfaces.ts'), 'modules/stickers/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/stickers/stickers-mixin.ts'), 'modules/stickers/stickers-mixin.ts')
  resolveStoreModules(require('../src/store/modules/stickers/store.ts'), 'modules/stickers/store.ts')
  resolveStoreModules(require('../src/store/modules/subscribers/interfaces.ts'), 'modules/subscribers/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/subscribers/store.ts'), 'modules/subscribers/store.ts')
  resolveStoreModules(require('../src/store/modules/subscribers/subscribers-mixin.ts'), 'modules/subscribers/subscribers-mixin.ts')
  resolveStoreModules(require('../src/store/modules/templates/interfaces.ts'), 'modules/templates/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/templates/store.ts'), 'modules/templates/store.ts')
  resolveStoreModules(require('../src/store/modules/templates/templates-mixin.ts'), 'modules/templates/templates-mixin.ts')
  resolveStoreModules(require('../src/store/modules/users-type/interfaces.ts'), 'modules/users-type/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/users-type/store.ts'), 'modules/users-type/store.ts')
  resolveStoreModules(require('../src/store/modules/users-type/user-types-mixin.ts'), 'modules/users-type/user-types-mixin.ts')
  resolveStoreModules(require('../src/store/modules/users/interfaces.ts'), 'modules/users/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/users/store.ts'), 'modules/users/store.ts')
  resolveStoreModules(require('../src/store/modules/users/users-mixin.ts'), 'modules/users/users-mixin.ts')
  resolveStoreModules(require('../src/store/modules/widgets/interfaces.ts'), 'modules/widgets/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/widgets/store.ts'), 'modules/widgets/store.ts')
  resolveStoreModules(require('../src/store/modules/widgets/widgets-mixin.ts'), 'modules/widgets/widgets-mixin.ts')
  resolveStoreModules(require('../src/store/modules/word-adjectives/interfaces.ts'), 'modules/word-adjectives/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/word-adjectives/store.ts'), 'modules/word-adjectives/store.ts')
  resolveStoreModules(require('../src/store/modules/word-adjectives/word-adjectives-mixin.ts'), 'modules/word-adjectives/word-adjectives-mixin.ts')
  resolveStoreModules(require('../src/store/modules/word-cases/interfaces.ts'), 'modules/word-cases/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/word-cases/store.ts'), 'modules/word-cases/store.ts')
  resolveStoreModules(require('../src/store/modules/word-cases/word-cases-mixin.ts'), 'modules/word-cases/word-cases-mixin.ts')
  resolveStoreModules(require('../src/store/modules/word-plurals/interfaces.ts'), 'modules/word-plurals/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/word-plurals/store.ts'), 'modules/word-plurals/store.ts')
  resolveStoreModules(require('../src/store/modules/word-plurals/word-plurals-mixin.ts'), 'modules/word-plurals/word-plurals-mixin.ts')
  resolveStoreModules(require('../src/store/modules/word-replaces/interfaces.ts'), 'modules/word-replaces/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/word-replaces/store.ts'), 'modules/word-replaces/store.ts')
  resolveStoreModules(require('../src/store/modules/word-replaces/word-replaces-mixin.ts'), 'modules/word-replaces/word-replaces-mixin.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/index.ts'), 'modules/tasks/store/index.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/tasks/index.ts'), 'modules/tasks/store/tasks/index.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-user-options/index.ts'), 'modules/tasks/store/task-user-options/index.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-statuses/index.ts'), 'modules/tasks/store/task-statuses/index.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-options/index.ts'), 'modules/tasks/store/task-options/index.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-options/interfaces.ts'), 'modules/tasks/store/task-options/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-options/mixin.ts'), 'modules/tasks/store/task-options/mixin.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-options/store.ts'), 'modules/tasks/store/task-options/store.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-statuses/interfaces.ts'), 'modules/tasks/store/task-statuses/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-statuses/mixin.ts'), 'modules/tasks/store/task-statuses/mixin.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-statuses/store.ts'), 'modules/tasks/store/task-statuses/store.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-user-options/interfaces.ts'), 'modules/tasks/store/task-user-options/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-user-options/mixin.ts'), 'modules/tasks/store/task-user-options/mixin.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/task-user-options/store.ts'), 'modules/tasks/store/task-user-options/store.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/tasks/interfaces.ts'), 'modules/tasks/store/tasks/interfaces.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/tasks/mixin.ts'), 'modules/tasks/store/tasks/mixin.ts')
  resolveStoreModules(require('../src/store/modules/tasks/store/tasks/store.ts'), 'modules/tasks/store/tasks/store.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
